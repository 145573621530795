<template>
  <v-container  >
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="4"
      >
        <v-container
          class="pa-2"
          tile
        >
          <card dataImage="../designs/Mordechai_bcard.jpg">
    
    <h1 slot="header"></h1>
    <p slot="content"></p>
  </card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Card from '../components/Card'
  export default {
    name: 'Grid',
    components: {
      Card,
    },
  }
</script>
<style>
.v-main__wrap {background-color: rgb(24, 24, 24);}
</style>
