<template>
<div>
  <hello-world />
  <grid />
  </div>
</template>

<script>
  import HelloWorld from '../components/HelloWorld'
  import Grid from '../components/Grid'

  export default {
    name: 'Home',

    components: {
      HelloWorld,
      Grid,
    },
  }
</script>
